@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
:root {
    --main-bg-color: #EF4E24;
}

@font-face {
    font-family: 'Handelson Two';
    src: url('/SVN-Handelson Two.otf');
}

@font-face {
    font-family: 'Segoe UI';
    src: url('/segoeui.ttf');
}

@font-face {
    font-family: 'Segoe UI';
    src: url('/seguisb.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('/segoeuib.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('/segoeuibl.ttf');
    font-weight: 800;
}

.font-content {
    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
}

body {
    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
    font-size: 16px;
}

.oswald {
    font-family: "Oswald", 'Segoe UI', sans-serif;
}
.App {
    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
    background-color: #eee;
    font-size: 16px;
    color: #111;
    .back-drop {
        display: none;
    }
    .back-drop.open {
        display: block;
    }
    .btn-group {
        position: relative;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        display: inline-flex;
        vertical-align: middle;
    }
    .dropdown-menu.show {
        display: block;
    }
    .nav-link {
        display: block;
        padding: 0.5rem 1rem;
        img {
            width: 100%;
        }
    }
}

.ant-message {
    top: 9px;
    .ant-message-notice {
        padding: 0;
        .ant-message-notice-content {
            border-radius: 0;
            border: 1px solid #ffccc7;
            background-color: #fff1f0;
            width: 100%;
            max-width: 500px;
            text-align: left;
            .ant-message-custom-content {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
                font-weight: 400;
                color: #1b1b1b;
                i {
                    width: 15px;
                    font-size: 20px;
                    top: 0;
                    width: 20px;
                }
                .anticon {
                    display: flex;
                    align-items: center;
                    top: 0;
                }
            }
        }
    }
}

.dropdown-custom {
    width: 100%;
    position: relative;
    .btn-custom {
        text-transform: uppercase;
        width: 100%;
        border: none;
        font-size: 22px;
        // font-family:  'Segoe UI', Helvetica, Arial, sans-serif;
        border-radius: 0;
        background: #ffcc33;
        box-shadow: none;
        padding: 8px 5px;
        text-align: center;
        cursor: pointer;
        &::after {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 0.255em;
            vertical-align: 0;
            content: '';
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
            font-size: 45px;
        }
    }
    ul {
        width: 100%;
        padding: 0;
        li {
            font-size: 25px;
            font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
            border-bottom: 1px solid #ccc;
            text-transform: uppercase;
            cursor: pointer;
            &:hover {
                background-color: rgb(63, 63, 63);
                color: #fff;
            }
        }
    }
}

.dropdown-menu {
    position: absolute;
    max-height: 400px;
    overflow-y: auto;
    top: 100%;
    left: 0px;
    will-change: transform;
    position: absolute;
    z-index: 1000;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #1b1b1b;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    .dropdown-item {
        display: block;
        width: 100%;
        padding: 0.25rem 1.5rem;
        clear: both;
        font-weight: 400;
        color: #212529;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
    }
}

@media screen and (min-width: 767px) {
    .ant-notification {
        margin-left: 50% !important;
        transform: translateX(-50%);
    }
}

.ant-notification {
    width: 100% !important;
    max-width: 500px !important;
    left: 0;
    top: 0 !important;
    .ant-notification-notice {
        border-radius: 0;
        padding: 12px;
        width: auto;
        .ant-notification-notice-content {
            color: #000;
            font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
            font-weight: 400;
            .ant-notification-notice-message {
                font-weight: 600;
            }
            .ant-notification-notice-message,
            .ant-notification-notice-description {
                font-size: 18px;
                margin-left: 40px;
            }
            .ant-notification-notice-close {
                right: 15px;
            }
        }
    }
    .error {
        background: #f3afb3;
    }
    .success {
        background: rgba(166, 239, 184, 0.9);
    }
    .ant-notification-hook-holder,
    .ant-notification-notice {
        max-width: 100%;
    }
    .error-location {
        background-color: #fdf4b6;
        &.ant-notification-notice {
            .ant-notification-notice-content {
                font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
                font-weight: 400;
                color: #222;
            }
            .ant-notification-notice-description {
                margin-left: 0;
                padding: 0 15px;
                font-size: 14px;
                text-align: center;
                @media only screen and (min-width: 768px) {
                    font-size: 16px;
                }
            }
        }
        .ant-notification-notice-close {
            right: 10px;
            top: 5px;
        }
        &__icon img {
            width: 16px;
            height: 16px;
        }
    }
}

.ant-modal-title {
    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
    font-size: 22px;
    font-weight: 600;
}

.background-spin {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.45);
    .ant-spin {
        .ant-spin-dot-item {
            background: #ffcc33;
        }
    }
    .ant-spin-text {
        color: #ffcc33;
    }
    .center-middle {
        top: 45%;
        border-radius: 50%;
    }
}

.loading {
    width: 55px;
    height: 55px;
    background: #fff;
}

.fixed {
    position: fixed;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.space-between {
    justify-content: space-between;
}

.item-center {
    align-items: center;
}

.item-baseline {
    align-items: baseline;
}

.content-center-middle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ant-tabs-nav {
    margin-bottom: 0 !important;
}

@media screen and (max-width: 768px) {
    .dropdown-custom {
        .btn-custom {
            font-size: 6.1vw;
        }
    }
    .ant-notification {
        .ant-notification-notice {
            .ant-notification-notice-content {
                .ant-notification-notice-message,
                .ant-notification-notice-description {
                    font-size: 4.5vw;
                }
            }
        }
    }
}

.ant-modal-content {
    border-radius: 0 !important;
    .ant-modal-header {
        padding: 10px 24px;
        background: initial;
        font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
    }
    .ant-modal-body {
        font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
        font-weight: 400;
        color: #111;
        font-size: 16px;
    }
    .ant-modal-close-x {
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            margin: auto;
        }
    }
}

.see-all {
    font-size: 14px;
    font-family: HelveticaVn-LtCn;
    font-weight: bold;
    cursor: pointer;
    color: #111;
}

.btn-color-1 {
    background-color: var(--main-bg-color) !important;
    border-color: var(--main-bg-color) !important;
    color: #fff !important;
    &:active {
        background-color: #ccc !important;
        border-color: var(--main-bg-color) !important;
    }
    &:focus {
        background-color: var(--main-bg-color) !important;
        border-color: var(--main-bg-color) !important;
    }
    &:hover {
        background-color: #0e285c !important;
        border-color: #0e285c !important;
    }
}

.shadown {
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.bg-30s {
    background-color: #20489B;
    color: #fff;
    &:hover {
        background-color: #0e285c !important;
        border-color: #0e285c !important;
    }
}

.cl-30s {
    color: #20489B;
}

.cl-white {
    color: #fff;
}

.cl-ccc {
    color: #ccc;
}

.bg-e8 {
    background-color: #e8e8e8;
}

.bg-ccc {
    background-color: #ccc;
}

.br-2 {
    border-radius: 2px;
}

.br-4 {
    border-radius: 4px;
}

.w-100 {
    width: 100%;
}

.placehoder {
    background-color: hsl(0, 0%, 89%);
}

.mg-10 {
    margin: 10px;
}

.mg-top-20 {
    margin-top: 20px;
}

.mg-top-10 {
    margin-top: 10px;
}

.mg-top-15 {
    margin-top: 15px;
}

.mg-left-15 {
    margin-left: 15px;
}

.mg-right-15 {
    margin-right: 15px;
}

.mb-30 {
    margin-bottom: 30px;
}

.padding-5 {
    padding: 5px;
}

.padding-10 {
    padding: 10px;
}

.padding-8 {
    padding: 8px;
}

.h1 {
    font-size: 20px;
    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
}

.h2 {
    font-size: 18px;
}

.h3 {
    font-size: 16px;
}

.h4 {
    font-size: 14px;
}

.bold {
    font-weight: bold;
}

.btn {
    cursor: pointer;
    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
}

.pointer {
    cursor: pointer;
}

.f-be {
    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
    font-weight: 400;
}

.f-oswald {
    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
}

.fb_dialog {
    z-index: 90 !important;
}

#fb-root {
    height: 0;
    overflow: hidden;
}

.bot-message {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background: #f0f8ff;
    border: 1px solid #9095c0;
    box-sizing: border-box;
    border-radius: 8px;
    width: calc(100% + 40px);
    z-index: 1;
    margin-left: -30px;
    padding: 8px;
    &__normal {
        width: 100%;
        margin-left: 0;
    }
    &__reception {
        flex-direction: row-reverse;
        img {
            margin-right: 0px !important;
            border-radius: 18px;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
            margin-left: 5px;
        }
        .avatar {
            width: 55px;
            display: flex;
            .reception-avatar {
                display: inline-block;
                background-color: #f7f7f7;
                width: 55px;
                height: 55px;
                background-size: cover;
                background-position: top;
                border-radius: 18px;
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
            }
        }
        .content {
            width: auto;
        }
    }
    .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        &__header {
            text-align: left;
            .label {
                font-weight: bold;
                color: #3d3d3d;
                font-size: 14px;
            }
            .name {
                font-size: 14px;
            }
        }
        p,
        span {
            text-align: left;
        }
    }
    img {
        width: 55px;
        height: 100%;
        margin-right: 10px;
        border-radius: 18px;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    }
    span,
    p {
        padding: 0 5px;
        font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
        font-weight: 400;
        font-size: 12px;
        margin: 0;
    }
}

.f-be-pro {
    font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
}

$color-eaf6ee: #eaf6ee;
$color-f7f7f7: #f7f7f7;
$color-a3a3a3: #a3a3a3;
$color-111: #111111;
$color-red-f46969: #f46969;
$color-red-ea1601: #ea1601;
$color-red-fd8b7c: #fd8b7c;
$color-green-eaf6ee: #eaf6ee;
$color-green-11b14b: #11b14b;
$color-gray-bababa: #bababa;
$color-gray-e8e8e8: #e8e8e8;
$color-gray-D1D1D1: #D1D1D1;
$color-FFF4B6: #fff4b6;
.bg-f7f7f7 {
    background-color: $color-f7f7f7;
}

.border-f7f7f7 {
    border-color: $color-f7f7f7;
}

.color-a3a3a3 {
    color: $color-a3a3a3;
}

.color-111111 {
    color: $color-111;
}

.color-red-f46969 {
    color: $color-red-f46969;
}

.border-red-f46969 {
    border-color: $color-red-f46969;
}

.bg-red-ea1601 {
    background-color: $color-red-ea1601;
}

.ping-fast {
    animation: ping 0.5s cubic-bezier(0, 0, 0.2, 1) infinite;
}

.bg-green-eaf6ee {
    background-color: $color-green-eaf6ee;
}

.border-gray-bababa {
    border-color: $color-gray-bababa;
}

.bg-red-fd8b7c {
    background-color: $color-red-fd8b7c;
}

.bg-green-11b14b {
    background-color: $color-green-11b14b;
}

.color-green-11b14b {
    color: $color-green-11b14b;
}

.border-gray-e8 {
    border-color: $color-gray-e8e8e8;
}

.bg-gray-e8 {
    background-color: $color-gray-e8e8e8;
}

.border-gray-d1 {
    border-color: $color-gray-D1D1D1;
}

.border-gray-a3 {
    border-color: $color-a3a3a3;
}

.text-767676 {
    color: #767676;
}

.bg-FFF4B6 {
    background-color: #fff4b6;
}

img {
    display: inline;
}

.modal_sercurity {
    top: 5% !important;
    max-width: 750px;
    width: 96vw !important;
    p {
        margin-bottom: 6px;
    }
    strong {
        font-weight: 600;
    }
    .ant-modal-close {
        display: none;
    }
    .ant-modal-content {
        margin: auto;
        max-width: 750px;
        width: 96vw;
        border-radius: 12px !important;
        height: 90%;
        max-height: 740px;
        .ant-modal-body {
            display: flex;
            flex-direction: column;
            height: 100%;
            .content {
                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    border-radius: 10px;
                    background-color: #f5f5f5;
                }
                &::-webkit-scrollbar {
                    width: 4px;
                    background-color: #f5f5f5;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    background-color: #555;
                }
            }
        }
    }
    .ant-modal-footer {
        display: none;
    }
    .label {
        font-size: 20px;
        font-weight: 600;
        text-align: center;
    }
    .content {
        font-size: 16px;
        max-width: 682px;
        width: 86vw;
    }
    .group_checkbox {
        margin: 12px 0;
        display: flex;
        align-items: center;
        column-gap: 12px;
    }
    .button_submit {
        display: block;
        margin: auto;
        border-radius: 4px;
        background-color: #e8e8e8;
        font-size: 16px;
        font-weight: 600;
        border: none;
        padding: 8px 40px;
        height: 40px;
        pointer-events: none;
        &.active {
            pointer-events: all;
            background-color: #20489B;
            color: #fff;
            &:hover {
                color: black;
                background-color: white;
                border: 1px solid black;
            }
        }
    }
    .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        transition: none;
    }
    .ant-checkbox-input {
        &:focus,
        &:hover {
            outline: none;
            border: none;
        }
    }
    .ant-checkbox-checked {
        .ant-checkbox-inner {
            background-color: #194190;
            border-color: #194190;
            width: 20px;
            height: 20px;
        }
        &:after {
            animation: none;
        }
    }
}

@media all and (max-width: 500px) {
    // .content {
    //         max-height: 45vh;
    //         overflow: auto;
    //     }
    .grecaptcha-badge {
        visibility: hidden;
    }
}

.animate-slideUp {
    animation-name: slideUp;
    animation-duration: 0.3s;
    animation-timing-function: ease;
}

@keyframes slideUp {
    from {
        transform: translateY(30%);
        height: 0;
    }
    to {
        transform: translateY(0);
        height: 100%;
    }
}

.animate-slideDown {
    animation-name: slideDown;
    animation-duration: 0.3s;
    animation-timing-function: ease;
    transform: translateY(100%);
    height: 0;
}

@keyframes slideDown {
    from {
        transform: translateY(0);
        height: 100%;
    }
    to {
        transform: translateY(100%);
        height: 0;
    }
}

.animate-slideShow {
    animation-name: slideShow;
    animation-duration: 0.3s;
    animation-timing-function: ease;
}

@keyframes slideShow {
    from {
        top: -30%;
    }
    to {
        top: 0;
    }
}

.animate-slideHide {
    animation-name: slideHide;
    animation-duration: 0.3s;
    animation-timing-function: ease;
}

@keyframes slideHide {
    from {
        top: 0%;
    }
    to {
        top: -30%;
    }
}
