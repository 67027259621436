.modal-booking-v2 {
    position: relative;
    .ant-modal-content {
        background: #ffffff;
        box-shadow: 0px 2px 12px rgba(89, 89, 89, 0.25);
        border-radius: 4px !important;
        padding: 16px 24px 24px 24px;
    }

    .close-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;

        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }

    .phone-error {
        color: #ff4d4f;
        font-size: 13px;
        margin-bottom: 10px;
        margin-left: 2px;
        display: block;
    }

    .button-booking {
        display: inline-block;
        width: 100%;
        background: #20489B;
        height: auto;
        padding: 8px;
        border-radius: 4px;

        span {
            color: #fff;
            font-weight: bold;
            font-size: 18px;
        }
    }

    .ant-modal-body {
        padding: 0px;
    }

    .modal-booking_title {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: #000000;
        margin-top: 10px;
        margin-bottom: 10px;

        img {
            width: 24px;
            margin-left: 5px;
        }
    }

    .modal-booking_description {
        font-size: 16px;
        margin-bottom: 24px;
    }

    .modal-booking_input-phone {
        padding: 10px 18px;
        border-radius: 4px;
        margin-bottom: 2px;

        &.error {
            border: 1px solid #ff4d4f;
            &:hover,
            &:focus {
                border-color: #ff4d4f;
            }
        }

        &::placeholder {
            color: #a3a3a3;
        }

        &:hover,
        &:focus {
            box-shadow: none;
            border-color: #D1D1D1;
            outline: none;
        }
    }

    .modal-booking_input-name {
        padding: 10px 18px;
        border-radius: 4px;
        margin-bottom: 2px;

        &::placeholder {
            color: #a3a3a3;
        }

        &.error {
            border: 1px solid #ff4d4f;
            &:hover,
            &:focus {
                border-color: #ff4d4f;
            }
        }

        &:hover,
        &:focus {
            box-shadow: none;
            border-color: #D1D1D1;
            outline: none;
        }
    }

    .link_to_home {
        margin-top: 12px;
        color: #000000;
        padding: 0px !important;
        text-align: center;

        &:hover {
            opacity: 0.7;
        }

        span {
            text-decoration-line: underline;
            font-size: 16px;
            font-weight: 600;
        }
    }
}
