.modal-promo-container {
  overflow: hidden;
}

.modal-promo-container,
.modal-promo-container * {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  outline: 0;
}

.modal-promo-container {
  position: relative;
  margin: 0 auto;
}
.frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
  gap: 18px;
  position: relative;
  width: 276px;
  margin: 0 auto;
  z-index: 1;
}
.frame-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 5px;
  position: relative;
  z-index: 2;
}
.isolation-mode {
  flex-shrink: 0;
  position: relative;
  width: 224.33px;
  height: 110.212px;
  z-index: 3;
  overflow: hidden;
}
.vector {
  position: absolute;
  width: 80.23%;
  height: 100%;
  top: 0%;
  left: 9.43%;
  background: url(./assets/images/f3afb5f2-7830-431a-aca7-f87e459eb982.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 5;
}
.vector-2 {
  position: absolute;
  width: 9.48%;
  height: 99.99%;
  top: 0%;
  left: 90.52%;
  background: url(./assets/images/71a4a2cb-0058-42e6-8ae3-ef91f36becfd.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 6;
}
.vector-3 {
  position: absolute;
  width: 9.48%;
  height: 99.99%;
  top: 0%;
  left: 89.66%;
  background: url(./assets/images/58755636-0d11-407e-807f-8b55a956c5ce.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 7;
}
.vector-4 {
  position: absolute;
  width: 9.42%;
  height: 99.99%;
  top: 0;
  left: 0;
  background: url(./assets/images/66021bd7-08f5-4a78-bc62-343bb158f61c.png)
    no-repeat center;
  background-size: 100% 100%;
  z-index: 4;
}
.max-discount {
  position: absolute;
  width: 174px;
  height: 84.38%;
  top: 4.08%;
  left: 13.04%;
  font-family: SVN-Gilroy, var(--default-font-family);
  font-size: 18.006723403930664px;
  font-weight: 400;
  line-height: 27.01px;
  text-align: center;
  text-overflow: initial;
  white-space: nowrap;
  letter-spacing: -0.2px;
  z-index: 8;
}
.discount-percent {
  position: relative;
  color: #ffffff;
  font-family: SVN-Gilroy, var(--default-font-family);
  font-size: 36.01344680786133px;
  font-weight: 600;
  line-height: 43.216px;
  text-align: center;
  letter-spacing: -0.2px;
}
.max-50k-total-bill {
  position: relative;
  color: #ffffff;
  font-family: SVN-Gilroy, var(--default-font-family);
  font-size: 15.75588321685791px;
  font-weight: 500;
  line-height: 27.01px;
  text-align: center;
  letter-spacing: -0.2px;
}
.apply-next-moyo-gift {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  width: 276px;
  height: 32px;
  color: #20489b;
  font-family: Segoe UI, var(--default-font-family);
  font-size: 12px;
  font-weight: 600;
  line-height: 16.014px;
  text-align: center;
  letter-spacing: -0.13px;
  z-index: 9;
}
.button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-shrink: 0;
  position: relative;
  width: 180px;
  height: 38px;
  cursor: pointer;
  background: transparent;
  border: none;
  z-index: 10;
}
.button-5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-wrap: nowrap;
  flex-shrink: 0;
  gap: 6px;
  position: relative;
  min-width: 0;
  height: 38px;
  padding: 12px 16px 12px 16px;
  background: #20489b;
  border: 1px solid #20489b;
  z-index: 11;
  overflow: hidden;
  border-radius: 5px;
}
.button-5:hover {
  background-color: #20489B;
}
.view-details {
  flex-shrink: 0;
  flex-basis: auto;
  position: relative;
  height: 20px;
  color: #ffffff;
  font-family: Segoe UI, var(--default-font-family);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  white-space: nowrap;
  z-index: 12;
}
.rectangle {
  position: absolute;
  width: 362px;
  height: 253px;
  top: 0;
  left: 0;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}
