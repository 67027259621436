@import '../../utils/mixin.scss';
.home {
    background-color: #f5f5f7;
    padding: 0px 0px 1px;
    .container {
        background-color: #f5f5f7;
        padding: 0 15px;
        margin: auto;
        width: 100%;
        max-width: 954px;
        @include breakpoint(sx) {
            padding: 0 10px;
        }
    }
}
.popup-banner-home {
    padding: 0;
    .ant-modal-content {
        margin: 0 15px;
        border-radius: 5px !important;
        overflow: hidden;
        .ant-modal-body {
            padding: 0;
        }
        .ant-modal-close {
            top: 7px;
            right: 7px;
            .ant-modal-close-x {
                width: 30px;
                height: 30px;
                background-color: rgba(238, 238, 238, 0.8);
                border-radius: 50%;
            }
        }
    }
    .ant-modal-footer {
        display: none;
    }
}
.rating-modal-confirm {
    .ant-modal-body {
        border-radius: 8px;
        padding: 25px 15px 15px;
        background: #fff;
    }
    .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-btns {
            position: absolute;
            top: 0px;
            right: 3px;
            margin: 0;
            float: inherit;
            .ant-btn-text {
                display: flex;
                align-items: center;
                background: transparent;
                padding: 0;
            }
        }
        .ant-modal-confirm-body {
            .anticon {
                display: none;
            }
            .ant-modal-confirm-content {
                margin: 0;
            }
        }
    }
    .ant-modal-content {
        border-radius: 8px !important;
    }
}
