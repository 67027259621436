.form-login-V2 {
    .form {
        &__text {
            text-align: center;
            margin-bottom: 30px;
        }
        &__getPassword {
            font-size: 14px;
            margin-bottom: 25px;
            padding: 0;
            text-align: right;
            span {
                cursor: pointer;
                text-decoration: underline;
            }
        }
        &__input {
            margin-bottom: 25px;
        }
        &__input-pin {
            margin-bottom: 0;
            > div {
                justify-content: space-between;
            }
            input {
                width: 48px !important;
                margin-bottom: 25px;
            }
        }
        &__button {
            width: 100%;
            border-radius: 4px;
            height: 48px;
            font-family: 'Segoe UI', sans-serif;
            font-size: 18px;
        }
        &__message {
            color: #e54d3e;
            font-size: 12px;
            margin-top: -20px;
            padding: 0px 5px 5px;
        }
        &__input {
            margin-bottom: 25px;
        }
        &__input-pin {
            margin-bottom: 0;
            > div {
                justify-content: space-between;
            }
            input {
                width: 48px !important;
                margin-bottom: 25px;
            }
        }
        &__button {
            width: 100%;
            border-radius: 4px;
            height: 48px;
            font-family: 'Segoe UI', Helvetica, Arial, sans-serif;
            font-size: 18px;
            background: var(--main-bg-color) !important;
            border-color: var(--main-bg-color) !important;
        }
        &__message {
            color: #e54d3e;
            font-size: 12px;
            margin-top: -20px;
            padding: 0px 5px 5px;
        }
    }
    .sendOTP {
        margin-bottom: 25px;
        font-size: 14px;
        &__count {
            color: #e54d3e;
        }
        &__resend {
            text-decoration: underline;
            cursor: pointer;
            &.hide {
                cursor: default;
                text-decoration: none;
                padding-right: 5px;
                color: #a3a3a3;
            }
        }
    }
    input {
        height: 48px;
        border-radius: 4px;
        border: 2px solid #a3a3a3;
        color: #222;
        &::placeholder {
            color: #a3a3a3;
        }
        &:focus {
            box-shadow: none;
            border-right-width: 2px !important;
            border-color: #a3a3a3;
        }
        &:hover {
            border-right-width: 2px !important;
            border-color: #a3a3a3;
        }
    }
    .btn-back {
        position: absolute;
        top: 20px;
        width: 32px;
        height: 32px;
        background: #e5e5e5;
        display: flex;
        justify-content: center;
        border-radius: 50%;
    }
    .btn-color-skip {
        border: 2px solid #000000;
        border-radius: 4px;
        margin-right: 18px;
        background: #fff;
        color: #222;
    }
}
